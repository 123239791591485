// accordion.ts
import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

const rounded = defineStyle({
    container: {
        w: "full",
        rounded: "lg",
        bg: "white",
    },
    button: {
        bg: "white",
        border: "1px solid",
        rounded: "lg",
        p: "6",
        w: "full",
        borderColor: "lightgrey",
        py: 2.5,
        _hover: {
            bg: "gray.50",
        },
        _expanded: {
            bg: "gray.100",
        },
    },
    panel: {
        py: 3,
        bg: "white",
    },
});

const lg = defineStyle({
    container: {
        w: "full",
        py: 4,
    },
    item: {
        p: "3",
        rounded: "lg",
    },
    button: {
        py: 3,
    },
    panel: {
        py: 4,
    },
});

const accordionTheme = defineStyleConfig({
    variants: {
        ...theme.components.Accordion?.variants,
        rounded,
    },

    sizes: {
        ...theme.components.Accordion?.sizes,
        lg,
    },
});

export default accordionTheme;
