export const stringToCamel = (string: string) => {
    return string.replace(/([-_][a-z])/gi, $1 => {
        return $1.toUpperCase().replace("-", "").replace("_", "");
    });
};

export function wordToCamelCase(string: string) {
    return string.toLowerCase().replace(/([-_\s][a-z])/gi, $1 => {
        return $1
            .toUpperCase()
            .replace("-", "")
            .replace("_", "")
            .replace(/\s/, "");
    });
}

export const stringToSnakeCase = (str: string): string => {
    return str
        .replace(/([a-z])([A-Z])/g, "$1_$2")
        .replace(/[-\s]+/g, "_")
        .replace(/^_+|_+$/g, "")
        .toLowerCase();
};

export const keysToCamelCase = (object: Object) => {
    const newObject: any = {};

    Object.keys(object).forEach(k => {
        newObject[stringToCamel(k)] = object[k];
    });

    return newObject;
};

export const keysToSnakeCase = (object: Object) => {
    const newObject: any = {};

    Object.keys(object).forEach(k => {
        newObject[stringToSnakeCase(k)] = object[k];
    });

    return newObject;
};
