import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";
import { tableAnatomy as parts } from "@chakra-ui/anatomy";

const {
    defineMultiStyleConfig,
    definePartsStyle,
} = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
    table: {},
    thead: {},
    tr: {},
    th: {
        "&[data-centered=true]": {
            textAlign: "center",
        },
    },
    td: {
        "&[data-centered=true]": {
            textAlign: "center",
        },
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        transition: "all 100ms ease-in-out",
    },
});

const variantPrimary = definePartsStyle(props => {
    return {
        ...theme.components.Table.variants.simple(props),
        table: {
            display: "table",
            borderColor: "gray.200",
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderRadius: "lg",
            borderCollapse: "inherit",
            borderSpacing: "0",
        },
        thead: {
            textTransform: "uppercase",
            bg: "gray.100",
            fontWeight: "medium",
            py: 3,
            h: 10,
        },
        tfoot: {
            textTransform: "uppercase",
            bg: "blue.50",
            fontWeight: "medium",
            py: 3,
            h: 9,
        },
        tr: {
            py: 2,
            borderBottomColor: "gray.200",
            borderBottomWidth: 1,
            p: 2,
        },
    };
});

const variantTasks = definePartsStyle(props => {
    return {
        ...theme.components.Table.variants.simple(props),
        table: {
            display: "table",
            borderColor: "gray.200",
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,

            borderRadius: "lg",
            borderCollapse: "inherit",
            borderSpacing: "0",
        },
        thead: {
            fontWeight: "normal",
            h: 10,
        },
        tr: {
            borderBottomColor: "gray.200",
            borderBottomWidth: 1,
        },
    };
});

const variantCovers = definePartsStyle(props => {
    return {
        ...theme.components.Table.variants.simple(props),
        table: {
            borderColor: "gray.200",
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderRadius: "lg",
            borderCollapse: "inherit",
            borderSpacing: "0",
        },
        thead: {
            bg: "gray.100",
            h: 10,
            fontWeight: "medium",
        },
        th: {
            "&[data-is-numeric=true]": {
                textAlign: "right",
            },
            color: "primary.700",
            fontSize: "xs",
            px: 2,
        },
        tr: {
            py: 2,
            borderBottomColor: "gray.200",
            borderBottomWidth: 1,
        },

        td: {
            "&[aria-selected=true]": {
                bg: "background.50",
            },
            borderColor: "gray.200",
            borderWidth: 1,
            textAlign: "center",
            wordBreak: "break-word",
            whiteSpace: "normal",
            fontWeight: "semibold",
            "&:first-of-type": {
                maxWidth: "300px",
                bg: "gray.50",
                textAlign: "left",
                color: "primary.700",
                position: "sticky",
                left: 0,
            },
        },
    };
});

const variantPayments = definePartsStyle(props => {
    return {
        ...theme.components.Table.variants.simple(props),
        table: {
            borderColor: "gray.200",
            borderWidth: 1,
            borderRadius: "lg",
            borderCollapse: "collapse",
            borderSpacing: "0",
        },
        thead: {
            bg: "gray.100",
            fontWeight: "medium",
        },
        tr: {
            py: 2,
            borderBottomColor: "gray.200",
            borderBottomWidth: 1,
            "&:not(:first-of-type)": { h: 10 },
        },
        th: {
            color: "primary.700",
            fontSize: "xs",
            paddingY: "0",
            borderWidth: 1,
            "&[data-is-centered=true]": {
                textAlign: "center",
            },
        },
        td: {
            borderColor: "gray.200",
            borderRightWidth: 1,
            borderBottomWidth: 1,
            color: "primary.700",
            fontWeight: "semibold",
            fontSize: "sm",
            textAlign: "center",
            "&[data-is-numeric=true]": {
                textAlign: "right",
            },
            _focusWithin: {
                bg: "background.50",
            },
        },
        tfoot: {
            tr: {
                th: {
                    borderInline: "1px",
                    borderBottom: "1px",
                    borderColor: "gray.200",
                },
            },
        },
    };
});

const variantPricing = definePartsStyle(props => {
    return {
        ...theme.components.Table.variants.simple(props),
        table: {
            width: "auto",
            borderColor: "gray.100",
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderRadius: "lg",
            borderCollapse: "inherit",
            borderSpacing: "0",
        },
        thead: {
            textTransform: "uppercase",
            fontWeight: "medium",
            h: "12",
        },
        tr: {
            borderColor: "gray.300",
            borderWidth: 1,
        },
        th: {
            "&[data-is-numeric=true]": {
                textAlign: "right",
            },
            "&[aria-selected=true]": {
                bg: "background.100",
            },
            textAlign: "center",
            borderColor: "gray.200",
            bg: "gray.100",
            borderWidth: 1,
            p: { fontSize: "2xl" },
            "&:first-of-type": {
                textAlign: "left",
                p: { fontSize: "sm" },
                bg: "gray.100",
                position: "sticky",
                left: 0,
                zIndex: 2,
            },
        },
        td: {
            "&[aria-selected=true]": {
                bg: "background.50",
            },
            borderColor: "gray.200",
            borderWidth: 1,
            textAlign: "center",
            minW: "3xs",
            wordBreak: "break-word",
            whiteSpace: "normal",
            "&:first-of-type": {
                bg: "gray.100",
                textAlign: "left",
                color: "primary.700",
                position: "sticky",
                left: 0,
                maxW: "sm",
            },
        },
    };
});

const variantClient = definePartsStyle(props => {
    return {
        ...theme.components.Table.variants.simple(props),
        table: {
            borderColor: "gray.200",
            borderRadius: "lg",
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderCollapse: "inherit",
            borderSpacing: "0",
        },
        thead: {
            bg: "gray.100",
        },
        th: {
            py: "3",
            fontSize: "xs",
            fontWeight: "medium",
            textTransform: "capitalize",
        },
        tr: {
            py: 4,
            borderBottomColor: "gray.200",
            borderBottomWidth: 1,
        },
        td: {
            py: "3",
        },
    };
});

const variantRenewals = definePartsStyle(props => {
    return {
        ...theme.components.Table.variants.simple(props),
        table: {
            borderColor: "gray.200",
            borderTopWidth: 1,
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderRadius: "lg",
            borderCollapse: "inherit",
            borderSpacing: "0",
        },
        thead: {
            textTransform: "uppercase",
            bg: "gray.100",
            fontWeight: "medium",
            py: 3,
            h: 10,
            _hover: {
                opacity: "none",
            },
        },
        tr: {
            height: "fit-content",
            py: 2,
            borderBottomColor: "gray.200",
            borderBottomWidth: 1,
            _hover: {
                opacity: 0.5,
                transitionDuration: "0.2s",
                transitionTimingFunction: "ease",
            },
        },
        tbody: {
            height: "fit-content",
            py: 2,
            borderBottomColor: "gray.200",
            borderBottomWidth: 1,
        },
    };
});

const variantInverted = definePartsStyle(props => {
    return {
        ...theme.components.Table.variants.simple(props),
        table: {
            width: "auto",
            minWidth: "300px",
            display: "table",
            height: "fit-content",
            borderColor: "gray.400",
            borderWidth: 1,
            borderCollapse: "inherit",
            borderSpacing: "0",
        },
        tr: { p: 1 },
        th: {
            p: 1,
            h: "30px",
            minWidth: "200px",
            width: "auto",
            color: "primary.700",
            textTransform: "uppercase",
            bg: "gray.100",
            fontWeight: "bold",
            borderBottomWidth: 1,
            borderColor: "transparent",
        },
        td: {
            p: 0,
            h: "30px",
            width: "200px",
            minWidth: "200px",
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderColor: "gray.200",
            textAlign: "center",
            _focusWithin: {
                background: "gray.50",
                borderBottomColor: "primary.300",
            },
            "&[data-is-numeric=true]": {
                textAlign: "right",
                "& > div": {
                    pr: 2,
                },
            },
            "&[data-is-action=true]": {
                width: "120px",
                minWidth: "120px",
                p: 0,
                "& button": {
                    maxWidth: "120px",
                    py: 1,
                },
            },
            "&[data-is-remove=true]": {
                p: 0,
            },
            "&:first-of-type": {
                borderLeftWidth: 1,
            },
        },
    };
});

const variantFixed = definePartsStyle(props => {
    return {
        ...theme.components.Table.variants.simple(props),
        table: {
            width: "auto",
            borderColor: "gray.200",
            borderWidth: 1,
            borderCollapse: "collapse",
            borderRadius: "lg",
            borderSpacing: "0",
        },
        thead: {
            bg: "gray.100",
            h: 10,
            fontWeight: "medium",
        },
        th: {
            "&[data-is-numeric=true]": {
                textAlign: "right",
            },
            color: "primary.700",
            fontSize: "xs",
            paddingY: "0",
        },
        tr: {
            py: 2,
            borderBottomColor: "gray.200",
            borderBottomWidth: 1,
        },
        td: {
            py: 1,
            borderColor: "gray.200",
            borderWidth: 1,
            color: "primary.700",
            fontSize: "sm",
            fontWeight: "500",
            width: "200px",
            "&[aria-selected=true]": {
                bg: "background.50",
            },
        },
        "&[data-is-numeric=true]": {
            textAlign: "right",
        },
    };
});

const variants = {
    primary: variantPrimary,
    pricing: variantPricing,
    covers: variantCovers,
    client: variantClient,
    renewals: variantRenewals,
    payments: variantPayments,
    inverted: variantInverted,
    fixed: variantFixed,
    tasks: variantTasks,
};

const tableTheme = defineMultiStyleConfig({
    baseStyle,
    variants,
    defaultProps: {
        variant: "primary",
        size: "sm",
    },
});

export default tableTheme;
